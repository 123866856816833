import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FileDownloadService } from '../services/file-download.service';
import { CommonTableComponent } from '../components/common-table/common-table.component';
import { CompanyService } from '../services/company.service';
import { CommonModule, JsonPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonTableComponent,
    JsonPipe,
    CommonModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'file-download-example';
  loader = false;

  tableColumns = [
    {key: 'name', label: 'Company Name'},
    {key: 'city', label: 'City'},
    {key: 'state', label: 'State'},
    {key: 'country', label: 'Country'},
    {key: 'primaryIndustry', label: 'Primary Industry'},
    {key: 'employeesCount', label: 'Employees'},
    {key: 'revenue', label: 'Revenue'},
    {key: 'website', label: 'Website'},
    {key: 'hqPhoneNumber', label: 'HQ Phone Number'},
    {key: 'description', label: 'Description'},
    {key: 'linkedinURL', label: 'LinkedIn URL'}
  ];
  tableRows = [];
  pagination: any;

  defaultPageSize = 25;
  defaultPageNumber = 1;

  filters = [
    {
      label: 'Company Name',
      attr: 'name',
      type: 'string',
      selected: false,
      selectedSubFilter: {
        condition: 'contains', // is, isnot, contain
        value: ''
      }
    },
    {
      label: 'City',
      attr: 'city',
      type: 'string',
      selected: false,
      selectedSubFilter: {
        condition: 'is',
        value: ''
      }
    },
    {
      label: 'State',
      attr: 'state',
      type: 'string',
      selected: false,
      selectedSubFilter: {
        condition: 'is',
        value: ''
      }
    },
    {
      label: 'Country',
      attr: 'country',
      type: 'string',
      selected: false,
      selectedSubFilter: {
        condition: 'is',
        value: ''
      }
    },
    {
      label: 'Primary Industry',
      attr: 'primaryIndustry',
      type: 'string',
      selected: false,
      selectedSubFilter: {
        condition: 'is',
        value: ''
      }
    },
    {
      label: 'Employees',
      attr: 'employeesCount',
      type: 'number',
      selected: false,
      selectedSubFilter: {
        condition: 'lessthan', // lessthan, greaterthan, between
        value: ''
      }
    },
    {
      label: 'Revenue',
      attr: 'revenue',
      type: 'number',
      selected: false,
      selectedSubFilter: {
        condition: 'lessthan', // lessthan, greaterthan, between
        value: ''
      }
    },
    {
      label: 'Website',
      attr: 'website',
      type: 'string',
      selected: false,
      selectedSubFilter: {
        condition: 'is',
        value: ''
      }
    },
    {
      label: 'HQ Phone Number',
      attr: 'hqPhoneNumber',
      type: 'string',
      selected: false,
      selectedSubFilter: {
        condition: 'is',
        value: ''
      }
    },
    {
      label: 'Description',
      attr: 'description',
      type: 'string',
      selected: false,
      selectedSubFilter: {
        condition: 'is',
        value: ''
      }
    },
    {
      label: 'LinkedIn URL',
      attr: 'linkedinURL',
      type: 'string',
      selected: false,
      selectedSubFilter: {
        condition: 'is',
        value: ''
      }
    }
  ];

  searchKey = '';

  constructor(private fileDownloadService: FileDownloadService, private cs: CompanyService) {}

  ngOnInit(): void {
    this.getCompanyList();
  }

  paginationChange(input: any) {
    console.log('paginationChange', input);
    if (input.type === 'page-size-change') {
      this.defaultPageSize = input.value;
      this.defaultPageNumber = 1;
    } else if (input.type === 'page-number-change') {
      this.defaultPageNumber = input.value;
    }
    this.getCompanyList();
  }
  
  getCompanyList() {
    this.loader = true;
    const payload = {
      filters: this.filters.filter(item => item.selected).map(item => {
        return {
          attr: item.attr,
          type: item.type,
          condition: item.selectedSubFilter.condition,
          value: item.selectedSubFilter.value
        }
      }),
      searchKey: this.searchKey
    };
    console.log('before', new Date().getTime())
    this.cs.getCompanyList(this.defaultPageNumber, this.defaultPageSize, payload).subscribe({
      next: (res: any) => {
        console.log('After', new Date().getTime())
        this.loader = false;
        this.tableRows = res.data;
        this.pagination = res.pagination;
        console.log(new Date().getTime(), res.pagination);
      },
      error: (error) => {
        this.loader = false;
        console.log('downloadFile error', error);
      }
    });
  }

  downloadFile() {
    const filename = 'CompanyDetails.csv';
    this.loader = true;
    this.fileDownloadService.downloadFile().subscribe(
    {
      next: (blob: any) => {
        this.loader = false;
        this.fileDownloadService.saveFile(blob, filename);
      },
      error: (error) => {
        this.loader = false;
        console.log('downloadFile error', error);
      }
    });
  }

  fileChangeEvent(event: any) {
    this.loader = true;
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    this.cs.importCompanyData(formData).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.defaultPageSize = 25;
        this.defaultPageNumber = 1;
        this.getCompanyList();
      },
      error: (error: any) => {
        this.loader = false;
        console.log('downloadFile error', error);
      }
    });
  }

  filterChange(event: any) {
    if (event !== undefined) {
      this.searchKey = event;
    } else {
      this.searchKey = '';
    }
    
    this.defaultPageNumber = 1;
    this.defaultPageSize = 25;
    this.getCompanyList();
  }
}
