<div class="loading" *ngIf="loader"></div>
<!-- <h1>Hi, Hello how are you..?</h1> -->
<div class="container-fluid">
  <div class="d-flex justify-content-between align-items-center">
    <h1>Company Details</h1>
    <div>
      <label class="btn btn-info me-2 mb-0">
        <input type="file" hidden accept=".csv" (change)="fileChangeEvent($event)"> Import
      </label>
      <button type="button" class="btn btn-success" (click)="downloadFile()">Export CSV</button>
    </div>
  </div>
  <app-common-table [cols]="tableColumns" [data]="tableRows" [pagination]="pagination" [filters]="filters" [searchKey]="searchKey" (paginationEmitter)="paginationChange($event)" (filterEmitter)="filterChange($event)"></app-common-table>
</div>
<router-outlet />
