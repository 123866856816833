import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class CompanyService {
    constructor(private http: HttpClient) { }

    getCompanyList(pageNumber?: any, pageSize?: any, payload?: any) {
      return this.http.post(`${environment.API_URL}admin/company/list?pageNumber=${pageNumber || 1}&pageSize=${pageSize || 25}`, payload || {});
    }

    importCompanyData(formData: any) {
      return this.http.post(`${environment.API_URL}admin/company/bulkUpload`, formData);
    }
  }