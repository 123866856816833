import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginationComponent } from '../pagination/pagination.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ArrayFilterPipe } from '../../pipes/array-filter.pipe';

@Component({
  selector: 'app-common-table',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    PaginationComponent,
    NgbDropdownModule,
    ArrayFilterPipe
  ],
  templateUrl: './common-table.component.html',
  styleUrl: './common-table.component.scss'
})
export class CommonTableComponent implements OnInit {
  filtersFlag = false;
  @Input() cols: any[] = [];
  @Input() data: any[] = [];
  @Input() pagination: any;
  @Input() filters: any[] = [];
  @Input() searchKey: string = '';
  @Output() paginationEmitter = new EventEmitter<any>();
  @Output() filterEmitter = new EventEmitter<any>();
  myTimeout: any;

  ngOnInit(): void {
    console.log(new Date().getTime(), this.pagination);
  }

  filtersFlagToggle() {
    this.searchKey = '';
    this.filtersFlag = !this.filtersFlag;
    if (this.filtersFlag === false) {
      this.filters.forEach(item => item.selected = false);
    }
    this.filterEmitter.emit();
  }

  searchFilter() {
    console.log('searchKey', this.searchKey);
    if (this.myTimeout) {
      clearTimeout(this.myTimeout);
    }
    this.myTimeout = setTimeout(() => {
      this.filterEmitter.emit(this.searchKey);
    }, 2000);
  }
}
