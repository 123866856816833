<div class="d-flex justify-content-between align-items-center mb-2" *ngIf="pagination">
    <div>
        Showing <select class="form-control" [(ngModel)]="pagination.pageSize" (change)="pagesizeChage()">
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>250</option>
            <option>500</option>
        </select>
         <span> {{ ((pagination.currentPage - 1) * pagination.pageSize) + 1  }} to {{ (pagination.currentPage) * pagination.pageSize }} of {{ pagination?.totalCount }} entries</span>
    </div>
    <nav aria-label="Page navigation example" class="">
        <span>Page Number(Max: {{ pagination.totalPages }}): </span><input type="text" class="form-control" [(ngModel)]="pagination.currentPage" placeholder="Enter" style="display: inline-block; width: auto;" (change)="pageNumberChange()">
        <!-- <select class="form-control mr-2" [(ngModel)]="pagination.currentPage" (change)="pageNumberChange()">
            <option *ngFor="let item of [].constructor(pagination.totalPages); let i = index">{{ i + 1 }}</option>
        </select> -->
        <!-- <ul class="pagination mb-0">
            <li class="page-item disabled">
                <a class="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li class="page-item active"><a class="page-link">1</a></li>
            <li class="page-item"><a class="page-link">2</a></li>
            <li class="page-item"><a class="page-link">....</a></li>
            <li class="page-item"><a class="page-link">5</a></li>
            <li class="page-item"><a class="page-link">6</a></li>
            <li class="page-item disabled">
                <a class="page-link" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul> -->
    </nav>
</div>