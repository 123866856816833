<div>
    <div class="mb-2">
        <div class="filter-section d-flex">
            <div class="input-search">
                <input [disabled]="filtersFlag" type="text" (input)="searchFilter()" class="form-control" [(ngModel)]="searchKey" placeholder="Search by Company Name, City, State, Country, Primary Industry, HQ Phone Number, Description">
                <i class="fa fa-search"></i>
            </div>
            <button type="button" class="btn btn-{{filtersFlag ? 'primary' : 'secondary'}} ms-2" (click)="filtersFlagToggle()">Filters &nbsp; <i class="fa fa-angle-{{filtersFlag ? 'down' : 'right'}}"></i></button>
        </div>
        <div class="mt-2" *ngIf="filters && filters.length > 0 && filtersFlag">
            <div *ngFor="let filter of filters | arrayFilter : true" class="chip"><strong>{{ filter.label }}</strong>&nbsp;{{ filter.type === 'number' ? 'is ' : '' }}{{ filter.selectedSubFilter.condition }}&nbsp;<strong>{{ filter.selectedSubFilter.value }}</strong> <span class="close" (click)="filter.selected = false; filterEmitter.emit()"><i class="fa fa-times"></i></span></div>
            <div ngbDropdown class="d-inline-block" #parentDrop="ngbDropdown">
                <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle title="Add new filter">
                    <i class="fa fa-plus"></i>
                </button>
                <div ngbDropdownMenu>
                    <div *ngFor="let filter of filters | arrayFilter : false">
                        <div ngbDropdown class="child-drop-down" placement="right" #myDrop="ngbDropdown">
                            <div ngbDropdownToggle>{{ filter.label }}</div>
                            <div ngbDropdownMenu>
                                <div>
                                    <ul class="filter-list">
                                        <li>
                                            <label *ngIf="filter.type === 'string'"><input type="radio" value="is" [(ngModel)]="filter.selectedSubFilter.condition"> is</label>
                                            <label *ngIf="filter.type === 'number'"><input type="radio" value="lessthan" [(ngModel)]="filter.selectedSubFilter.condition"> lessthan</label>
                                            <input type="text" *ngIf="filter.selectedSubFilter.condition === 'lessthan' || filter.selectedSubFilter.condition === 'is'" class="form-control form-control-sm" [(ngModel)]="filter.selectedSubFilter.value">
                                        </li>
                                        <li>
                                            <label *ngIf="filter.type === 'string'"><input type="radio" value="is not" [(ngModel)]="filter.selectedSubFilter.condition"> is not</label>
                                            <label *ngIf="filter.type === 'number'"><input type="radio" value="greaterthan" [(ngModel)]="filter.selectedSubFilter.condition"> greaterthan</label>
                                            <input type="text" *ngIf="filter.selectedSubFilter.condition === 'greaterthan' || filter.selectedSubFilter.condition === 'is not'" class="form-control form-control-sm" [(ngModel)]="filter.selectedSubFilter.value">
                                        </li>
                                        <li>
                                            <label *ngIf="filter.type === 'string'"><input type="radio" value="contains" [(ngModel)]="filter.selectedSubFilter.condition"> contains</label>
                                            <label *ngIf="filter.type === 'number'"><input type="radio" value="between" [(ngModel)]="filter.selectedSubFilter.condition"> between</label>
                                            <input type="text" *ngIf="filter.selectedSubFilter.condition === 'between' || filter.selectedSubFilter.condition === 'contains'" class="form-control form-control-sm" [(ngModel)]="filter.selectedSubFilter.value">
                                            <span class="help-text" *ngIf="filter.type === 'number' && filter.selectedSubFilter.condition === 'between'">Please enter 2 values by comma separated</span>
                                        </li>
                                    </ul>
                                    <div class="px-2">
                                        <button type="button" class="btn btn-sm btn-warning me-2" (click)="$event.stopPropagation(); myDrop.close();parentDrop.close()">Cancel</button>
                                        <button type="button" class="btn btn-sm btn-primary" (click)="filter.selected=true; filterEmitter.emit()">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div>
                        <div ngbDropdown class="child-drop-down" placement="right">
                            <div ngbDropdownToggle>City</div>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem>Chennai</button>
                            </div>
                        </div>
                    </div>
                    <button ngbDropdownItem>State</button>
                    <button ngbDropdownItem>Coutnry</button>
                    <button ngbDropdownItem>Primary Industry</button>
                    <button ngbDropdownItem>Employees</button>
                    <button ngbDropdownItem>Revenue</button>
                    <button ngbDropdownItem>Website</button>
                    <button ngbDropdownItem>HQ Phone Number</button>
                    <button ngbDropdownItem>Description</button>
                    <button ngbDropdownItem>LinkedIn URL</button> -->
                </div>
            </div>
            <!-- <button class="btn btn-secondary" type="button">+</button> -->
        </div>
    </div>
    <app-pagination *ngIf="pagination" [pagination]="pagination" (paginationEmitter)="paginationEmitter.emit($event)"></app-pagination>
    <table class="table table-bordered table-sm">
        <thead>
            <tr>
                <th *ngFor="let col of cols">
                    {{ col.label }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of data">
                <td *ngFor="let col of cols">
                    {{ row[col['key']] }}
                </td>
            </tr>
        </tbody>
    </table>
</div>