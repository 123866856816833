import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayFilter',
    pure: false,
    standalone: true
})
export class ArrayFilterPipe implements PipeTransform {
    transform(items: any[], filterVal?: boolean): any {
        // console.log('filter', filter);
        if (filterVal === undefined) {
            return items;
        }
        return items.filter(item => item.selected === filterVal);
    }
}