import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent implements OnInit, AfterViewInit {
  @Input() pagination: any = {
    pageSize: 25,
    currentPage: 1,
    totalCount: 10,
    totalPages: 1
  };
  @Output() paginationEmitter = new EventEmitter<any>();

  ngOnInit(): void {
    console.log(new Date().getTime(), this.pagination);
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', new Date().getTime(), this.pagination);
  }

  pagesizeChage() {
    this.paginationEmitter.emit({type: 'page-size-change', value: this.pagination.pageSize});
  }

  pageNumberChange() {
    this.paginationEmitter.emit({type: 'page-number-change', value: this.pagination.currentPage});
  }
}
